import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

export const msalConfig = (policy?: string) => {

    policy = policy ?? (window as any).REACT_APP_B2C_SIGNIN_POLICY;
    return {
        auth: {
            clientId: (window as any).REACT_APP_B2C_CLIENT_ID || '',
            redirectUri: window.location.origin,
            authority: `https://${(window as any).REACT_APP_B2C_INSTANCE}/${(window as any).REACT_APP_B2C_DOMAIN}/${policy}`,
            knownAuthorities: [(window as any).REACT_APP_B2C_INSTANCE || '']
        },

        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false,
        }
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "offline_access"]
};

export const tokenRequest = {
    scopes: [
        `https://${(window as any).REACT_APP_B2C_DOMAIN}/core-api/global.readwrite`
    ]
};
