
        if (import.meta.env.REACT_APP_ENVIRONMENT === 'local') {
            window.REACT_APP_HUB_SPOT_PORTAL_ID = "";
            window.REACT_APP_PAYMENT_KEY = "pk_test_aJHcKTIqHBzeZ3ihUBBMnpeS";
            window.REACT_APP_DOCUMENT_FUNCTION_URL = "https://localhost:7071";
            window.REACT_APP_ENVIRONMENT = "local";
            window.REACT_APP_APP_INSIGHTS_CONNECTION = "InstrumentationKey=a8de7a2d-ce2d-4f1e-99c3-0f9994158561;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/";
            window.REACT_APP_PROXY_URL = "https://localhost:7169";
            window.REACT_APP_CRM_PROXY_URL = "https://localhost:7237";
            window.REACT_APP_SCHEDULING_PROXY_URL = "https://localhost:7265";
            window.REACT_APP_SIGNALR_PROXY_URL = "https://localhost:7017";
            window.REACT_APP_TIME_PROXY_URL = "https://localhost:7139";
            // window.REACT_APP_B2C_CLIENT_ID = "0d15c354-5b50-4243-8945-5b3b5dcdab93";
            // window.REACT_APP_B2C_INSTANCE = "factordevl.b2clogin.com";
            // window.REACT_APP_B2C_DOMAIN = "factordevl.onmicrosoft.com";
            window.REACT_APP_B2C_CLIENT_ID = "6f911fd1-e8d9-4d29-8b8f-01786e36c9cc";
            window.REACT_APP_B2C_INSTANCE = "factordevzhamrick.b2clogin.com";
            window.REACT_APP_B2C_DOMAIN = "factordevzhamrick.onmicrosoft.com";
            window.REACT_APP_B2C_SIGNIN_POLICY = "B2C_1A_SIGNIN";
            window.REACT_APP_B2C_NEWUSER_POLICY = "B2C_1A_SIGNIN";
            window.REACT_APP_B2C_NEWFIRM_POLICY = "B2C_1A_SIGNIN";
            window.REACT_APP_B2C_CHANGEPASSWORD = "B2C_1A_CHANGEPASSWORD";
            window.REACT_APP_B2C_SIGNUP_POLICY = "B2C_1A_SIGNIN";
            window.REACT_APP_USER_MGMT_URL = "https://factorusermgmt-devl.azurewebsites.net";
            window.REACT_APP_COMMUNICATIONS_PROXY_URL = "https://localhost:7131";
            window.REACT_APP_FACTOR_PAY_SIGNUP_LINK = "https://test-portal.payrix.com/signup/FactorAppIncSignupForm";
        } else {
            window.REACT_APP_HUB_SPOT_PORTAL_ID = "__REACT_APP_HUB_SPOT_PORTAL_ID__";
            window.REACT_APP_PAYMENT_KEY = "__REACT_APP_PAYMENT_KEY__";
            window.REACT_APP_DOCUMENT_FUNCTION_URL = "__REACT_APP_DOCUMENT_FUNCTION_URL__";
            window.REACT_APP_ENVIRONMENT = "__REACT_APP_ENVIRONMENT__";
            window.REACT_APP_APP_INSIGHTS_CONNECTION = "__REACT_APP_APP_INSIGHTS_CONNECTION__";
            window.REACT_APP_PROXY_URL = "__REACT_APP_PROXY_URL__";
            window.REACT_APP_CRM_PROXY_URL = "__REACT_APP_CRM_PROXY_URL__";
            window.REACT_APP_SCHEDULING_PROXY_URL = "__REACT_APP_SCHEDULING_PROXY_URL__";
            window.REACT_APP_SIGNALR_PROXY_URL = "__REACT_APP_SIGNALR_PROXY_URL__";
            window.REACT_APP_TIME_PROXY_URL = "__REACT_APP_TIME_PROXY_URL__";
            window.REACT_APP_B2C_CLIENT_ID = "__REACT_APP_B2C_CLIENT_ID__";
            window.REACT_APP_B2C_INSTANCE = "__REACT_APP_B2C_INSTANCE__";
            window.REACT_APP_B2C_DOMAIN = "__REACT_APP_B2C_DOMAIN__";
            window.REACT_APP_B2C_SIGNIN_POLICY = "__REACT_APP_B2C_SIGNIN_POLICY__";
            window.REACT_APP_B2C_NEWUSER_POLICY = "__REACT_APP_B2C_NEWUSER_POLICY__";
            window.REACT_APP_B2C_NEWFIRM_POLICY = "__REACT_APP_B2C_NEWFIRM_POLICY__";
            window.REACT_APP_B2C_CHANGEPASSWORD = "__REACT_APP_B2C_CHANGEPASSWORD__";
            window.REACT_APP_B2C_SIGNUP_POLICY = "__REACT_APP_B2C_SIGNUP_POLICY__";
            window.REACT_APP_USER_MGMT_URL = "__REACT_APP_USER_MGMT_URL__";
            window.REACT_APP_COMMUNICATIONS_PROXY_URL = "__REACT_APP_COMMUNICATIONS_PROXY_URL__";
            window.REACT_APP_FACTOR_PAY_SIGNUP_LINK = "__REACT_APP_FACTOR_PAY_SIGNUP_LINK__";
        }
    